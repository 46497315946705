import React, { useState, useEffect } from "react";
import SubscriptionForm from "./SubscriptionDetails.jsx";
import ReviewPlaceOrder from "./ReviewOrder.jsx";
import Navbar from "../Dashboard/Navbar.jsx";
import '../PurchaceFlow/purchaceFlow.css';
import troubleicon from '../Assets/troubleicon.svg';
import contactSupport from "../Assets/contactSupport.svg"
import WelcomeOnboard from "./Closure.jsx";
import { useParams, useLocation } from 'react-router-dom';

const SubAddonFlow = () => {
    const [stage, setStage] = useState(1);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { country } = location.state || {};
    const { CustomerUid } = location.state || {};
    const { id } = useParams(); 
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const handleStageChange = (data) => {
        setFormData({
            ...formData,
            ...data,
            country,
            CustomerUid,
        });
        setStage(stage + 1);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                if (id){
                    const token = localStorage.getItem('token');
                    const response = await fetch(`/get/stage?id=${id}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `${token}`,
                        },
                    });
                    const data = await response.json();

                    // Map response to the next stage, skipping the removed "CreateAccount" stage
                    const stageMap = {
                        "stage1": 2,
                        "stage2": 2,
                        "stage3": 2,
                        "stage4": 3,
                    };

                    const nextStage = stageMap[data.stage];
                    if (nextStage) {
                        setStage(nextStage); // Move to the appropriate next stage
                        setLoading(false);
                    }
                    
                }
                else{
                    setLoading(false)
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [id]);

    const handleSetStage = (n) => {
        setStage(n);
        window.scrollTo(0, 0);
    };

    const steps = [
        { title: "Subscription Details", subtitle: "Set up your Subscription" },
        { title: "Place Order", subtitle: "Review and Place Order" },
        { title: "Order Processed", subtitle: "Fulfilment Pending" }
    ];

    const stages = {
        1: <SubscriptionForm onAction={handleStageChange} />,
        2: <ReviewPlaceOrder onAction={handleStageChange} countryData={formData} />,
        3: <WelcomeOnboard countryData={formData} itemName={formData}/>
    };

    if (loading) {
        return (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        );
    }

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="landing-page">
            <Navbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div className="container-fluid maincontent">
                <div className="row">
                    <div className={`col-3 S1col-4 new_sub sidebar ${sidebarOpen ? "open" : ""}`}>
                        <div className="LPnav-panel">
                            <div className="LPnav-options">
                                {steps.map((step, index) => (
                                    <React.Fragment key={index}>
                                        <div className="LPstep">
                                            <div
                                                className={`LPcircle ${index + 1 <= stage ? 'active' : ''}`}
                                                // onClick={() => handleSetStage(index + 1)}
                                            >
                                                <span className={`${index + 1 <= stage ? 'active' : ''}`}>{index + 1}</span>
                                            </div>
                                            <div className="LPstep-details">
                                                <div className={`LPtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.title}</div>
                                                <div className={`LPsubtitle ${index + 1 <= stage ? 'active' : ''}`}>{step.subtitle}</div>
                                            </div>
                                        </div>
                                        {index < steps.length - 1 && <div className="LPvertical-dashed-line"></div>}
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="LPhelp-section">
                                <img src={troubleicon} alt="Trouble Icon" />
                                <div className="LPhelp-details">
                                    <div className="LPhelp-title">Having trouble?</div>
                                    <div className="LPhelp-description">Feel free to contact us and we will always help you through the process.</div>
                                </div>
                                <a href="https://www.briotech.com/contact-us/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="LPcontact-button">
                                    <img src={contactSupport} alt="Contact Icon" />
                                        Contact Us
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`col-lg-9 S1col-8 new_sub ${sidebarOpen ? "hidden-content" : ""}`}>
                        {stages[stage] || null}  {/* Render the stage component or nothing */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubAddonFlow;