import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import buynow from '../Assets/buynow.svg';
import msicon2 from '../Assets/msicon2.svg';
import Card from '../../components/itemCard';
import Notification from '../../components/Notification';
import '../PurchaceFlow/purchaceFlow.css';

const SubscriptionForm = ({ onAction }) => {
    const location = useLocation();
    const { sku_id } = location.state || {};
    const { country } = location.state || {};
    const [count, setCount] = useState(1);
    const [subscriptionLength, setSubscriptionLength] = useState('');
    const [billingTerm, setBillingTerm] = useState('Annually');
    const [plans, setPlans] = useState([]);
    const [prices, setPrices] = useState({});
    const [productName, setProductName] = useState('');
    const [catalogItemId, setCatalogItemId] = useState('');
    const [itemId, setItemId] = useState('');
    const [loading, setLoading] = useState(true); // Loading state
    const [showModal, setShowModal] = useState(false);
    const [service, setService] = useState(null);
    const [selectedOption, setSelectedOption] = useState('existing_customer');
    const [existingSub, setExistingSub] = useState('');
    const [selectedDomain, setSelectedDomain] = useState('')
    const [selectedCust, setSelectedCust] = useState('');
    const [uid, setUid] = useState('');
    const [subscriptionId, setSubscriptionId] = useState('');
    const [filteredItems, setFilteredItems] = useState({})
    const [items, setItems] = useState();
    const currency = country === "IN" ? "INR" : country === "US" ? "USD" : null;
    const currencySymbols = { "INR": "₹", "USD": "$" };
    const country_console = country === "IN" ? "IP" : country === "US" ? "UP" : null;
    const token = localStorage.getItem('token');
    const customerId = localStorage.getItem('customerId');
    const user = localStorage.getItem('userId')
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (sku_id) {
            const url = `/subscriptiondetails?sku_id=${sku_id}&country=${country}`;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `${token}`,
                },
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Response Data:', data);
                    const annualPlans = data.availabilitydata[0]?.terms.filter(terms => terms === 'P1Y') || [];
                    const value = annualPlans[0] === 'P1Y' ? ['Annual'] : annualPlans[0] === 'P1M' ? ['Monthly'] : [''];
                    setPlans(value);
                    setSubscriptionLength(value.length > 0 ? value[0] : '');
                    setPrices({
                        Monthly: data.item?.monthly_price || {},
                        Annual: data.item?.annual_price || {},
                    });
                    setProductName(data.item.name || 'Product');
                    setCatalogItemId(data.availabilitydata[0]?.catalogItemId || '');
                    setItemId(data.item.id || '');
                    // setLoading(false);
                })
                .catch(error => {
                    console.error('Error making GET request:', error);
                });
        }

        const url = `/api/existingsubscriptions?customerId=${customerId}`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log("saadbrio", data.items);
            
            // Extract items and subscriptions
            const items = data.items;
            const subscriptions = data.subscriptions;
            if (!subscriptions || subscriptions.length === 0) {
                navigate("/home", {state:{error:"You must have base product for purchasing copilot for Microsoft 365"}})
            }

            // Map subscriptions to filter items
            subscriptions.forEach(sub => {
                // Extract the item from the subscription
                const itemId = sub.itemid;

                // Find the item in the items array
                const matchingItem = items.find(item => item.id === itemId);
                if (matchingItem) {
                    // Modify the domain as per requirement
                    
                    // Add the item to the filteredItems dictionary
                    filteredItems[sub.id] = {
                        name: matchingItem.name,
                        domain: sub.domain,
                        subscriptionId: sub.id,
                        uid: sub.customer_uid,
                        customerId:sub.customerid,
                    };
                }
            });

            const firstValue = filteredItems[Object.keys(filteredItems)[0]];
            setItems(items)
            setUid(firstValue.uid);
            setSubscriptionId(firstValue.subscriptionId);
            setSelectedDomain(firstValue.domain)
            setFilteredItems(filteredItems);
            setSelectedCust(firstValue.customerId)
            setExistingSub([firstValue.subscriptionId, firstValue.uid, firstValue.domain, firstValue.customerId])
            localStorage.setItem("selectedCust", firstValue.customerId);

            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
        });

    }, [sku_id]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d+$/.test(value) || value === '') {
            const newValue = value === '' ? '' : parseInt(value, 10);
            setCount(newValue === '' ? "" : Math.max(newValue, 0));
        }
    };

    const handleSubscriptionChange = (e) => setSubscriptionLength(e.target.id);

    const handleItemPopup = async () => {
        try {
            const token = localStorage.getItem('token');
            const sku = localStorage.getItem('item')
            const response = await fetch(`/item?sku=${sku}`, {
                method: 'GET',
                headers: {
                    'Authorization': `${token}`,
                },
            });
            const data = await response.json();
            setService(data); // Store the service data
            setShowModal(true); // Show the modal
        } catch (error) {
            console.error('Error fetching item details:', error);
        }
    };
    const handleNotificationClose = () => {
        setMessage('');
    };
    const handleCloseModal = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            setShowModal(false); // Close the modal if clicking outside the card
        }
    };

    const getSubscriptionPrice = () => {
        if (prices[subscriptionLength] && prices[subscriptionLength][currency]) {
            return parseFloat((prices[subscriptionLength][currency] * count).toFixed(2));
        }
        return 0;
    };


    const handleNext = () => {
        setLoading(true);
        if(count === "" || count <= 0){
            setMessage("Quantity required and it should be greater than 0");
            return false
        }
        const is_existing_customer = selectedOption === 'existing_customer'? "true":"false";
        const taxRate = country === "US" ? 0 : 0.18;
        const subtotal = getSubscriptionPrice();
        const taxAmount = parseFloat((subtotal * taxRate).toFixed(2));
        const totalCost = parseFloat((subtotal + taxAmount).toFixed(2));
        const unitPrice = prices[subscriptionLength]
        const payload = {
            sku_id,
            catalogItemId,
            itemId,
            count,
            subscriptionLength,
            billingTerm,
            taxRate,
            console: country_console,
            unit_price: unitPrice[currency] ,
            total: subtotal,
            totalCost:Math.round(totalCost),
            tax_amount: taxAmount,
            is_existing_customer:is_existing_customer,
            uid:uid,
            is_new_contract:"true",
            is_addon:'true',
            tenant:selectedDomain,
            parent_subscription_id:subscriptionId,
            id:selectedCust,
            user,
        };
        console.log(payload)
        fetch('/subscriptiondetails', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`,
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(result => {
                console.log('POST Request Result:', result);
                if (is_existing_customer === "true"){
                    navigate(`/subscription/addon/${result.id}`, { state: { country: country } });
                }
            })
            .catch(error => {
                console.error('Error making POST request:', error);
            });
    };

    const handleSubChange = (e) => {
        console.log(e)
        debugger

        const selectedValue = e.target.value;
        setExistingSub(selectedValue)
        const [sub, uid, domain, customerID] = selectedValue.split(",");
        setSubscriptionId(sub.trim());
        setUid(uid.trim());
        setSelectedDomain( domain.trim());
        setSelectedCust(customerID.trim())
        localStorage.setItem("selectedCust", customerID.trim());
    };
    return (
        <div className='S1subscription-form'>
            {loading && (
                <div className="modal-overlay">
                    <div className="preloader-modal">
                        <div className="spinner"></div>
                    </div>
                </div>
            )}
            <div className='PFServicesHeading'>
                <div className='ItemDetails-heading'>
                    <span>Set up your Subscription</span>
                </div>
                <p style={{marginTop:'5px'}}>Choose the number of users you prefer.</p>
            </div>

            <div className='S1fields'>
                <div className='ItemName sub_item' style={{marginTop: Object.keys(filteredItems).length === 0 && !loading ? '0%' : '5%',}} >
                    <p style={{fontSize: '16px'}}><strong>{productName}</strong></p>
                    <button className="view-plan-button sub_button" onClick={handleItemPopup}>
                        <img src={msicon2} alt="service-icon" className="ms-icon" />
                        View Service Details
                    </button>
                </div>
                <div>
                    {selectedOption === 'existing_customer' && (
                        <div class='drop-down-box' style={{ marginTop: '15px', position: 'relative' }}>
                        <label>Select Parent Subscription :</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <select
                                value={existingSub}
                                onChange={handleSubChange}
                                className='custom-select customer'
                            >
                                {Object.values(filteredItems).map(item => (
                                    <option key={[item.subscriptionId, item.uid, item.domain, item.customerid]} value={[item.subscriptionId, item.uid, item.domain, item.customerid]}>
                                        {item.name} ({item.domain})
                                    </option>
                                ))}
                            </select>
                            {/* <div className="info-icon" data-uid={customerOptions.find(opt => opt.uid === existingSub)?.uid}>
                                ⓘ
                                <span className="tooltip-text">
                                    Tenant Id: {customerOptions.find(opt => opt.uid === existingSub)?.uid}
                                </span>
                            </div> */}
                        </div>
                    </div>
                    )}
                </div>
                <div className='S1frame21'>
                    <div className='S1frame13'>
                        <label>How many people is this for? *</label>
                        <div className='S1counter-container'>
                            <input
                                className='S1count-input'
                                type='text'
                                placeholder='Quantity'
                                value={count}
                                onChange={handleInputChange}
                                onBlur={() => (count === '' || count === 0) && setCount(1)}
                            />
                        </div>
                    </div>
                    {plans.length > 0 && (
                        <div className='S1frame14'>
                            <label>Length of the subscription:</label>
                            {/**
                             * !!! IMPORTANT !!!
                             * Dont remove this commented code!
                             */}
                            {/* <div className='S1frame19'>
                                {plans.map(plan => (
                                    <div key={plan} className='S1frame17'>
                                        <input
                                            type='radio'
                                            id={plan}
                                            name='subscriptionLength'
                                            onChange={handleSubscriptionChange}
                                            checked={subscriptionLength === plan}
                                        />
                                        <label htmlFor={plan}>
                                            {plan === 'Monthly'
                                                ? `One Month - ${currencySymbols[currency]}${prices.Monthly ? prices.Monthly[currency] : 0} user/month`
                                                : `One Year - ${currencySymbols[currency]}${prices.Annual ? prices.Annual[currency] : 0} user/year`}
                                        </label>
                                    </div>
                                ))}
                            </div> */}
                            <div className='S1frame19'>
                                {plans.map(plan => (
                                    plan === 'Annual' && ( // Only render the 'Annual' plan
                                        <div key={plan} className='S1frame17'>
                                            <input
                                                type='radio'
                                                id={plan}
                                                name='subscriptionLength'
                                                onChange={handleSubscriptionChange}
                                                checked={subscriptionLength === plan}
                                            />
                                            <label htmlFor={plan}>
                                                One Year - {currencySymbols[currency]}{prices.Annual ? prices.Annual[currency] : 0} user/year
                                            </label>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className='S1frame23'>
                    <span>Your Order Summary:</span>
                    <div className='S1frame22'>
                        <div> {subscriptionLength === 'Monthly' ? '1 Month' : '1 Year'} Subscription</div>
                        <div>Pay {currencySymbols[currency]}{getSubscriptionPrice()} {subscriptionLength === 'Monthly' ? 'Monthly' : 'Yearly'} for {count} user{count > 1 ? 's' : ''}</div>
                    </div>
                </div>
                <div className='S1frame27 sub_next'>
                    <div className='S1frame25'>
                        <span>Payment Due Today (tax not included):</span>
                        <div className='S1amount add_sub'>{currencySymbols[currency]}{getSubscriptionPrice()}</div>
                    </div>
                    <div className={`S1frame26 ${Object.keys(filteredItems).length === 0 || loading ? 'disabled' : ''}`}
                        onClick={() => {
                            if (Object.keys(filteredItems).length > 0 && !loading) {
                                handleNext();
                            }
                        }}
                    >
                        <span>Next</span>
                        <img src={buynow} alt="Buy Now" />
                    </div>
                </div>
            </div>
            {message && (
                <div className="error-msg-notification-container">
                    <Notification key={Date.now()} message={message} onClose={handleNotificationClose} />
                </div>
            )}
            {showModal && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    {service && <Card service={service} type='preview' />}
                </div>
            )}
        </div>
    );
};

export default SubscriptionForm;
