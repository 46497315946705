import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SyncCP = () => {

    return (
        <div className="sync-page">
            
        </div>
    );
};

export default SyncCP;