import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Card from './Card';
import Pagination from './Pagination';
import Notification from '../../components/Notification';
import banerBackground from '../Assets/banerBackground.png';
import Hyderabad from '../Assets/Hyderabad.svg'
import Bengaluru from '../Assets/Bengaluru.svg'
import Chennai from '../Assets/Chennai.svg'
import DelhiNCR from '../Assets/DelhiNCR.svg'
import Mumbai from '../Assets/Mumbai.svg'
import Kochi from '../Assets/Kochi.svg'
import Vijayawada from '../Assets/Vijayawada.svg'
import DubaiUAE from '../Assets/DubaiUAE.svg'
import RiyadhKSA from '../Assets/RiyadhKSA.svg'
import DelawareUS from '../Assets/DelawareUS.svg'
import BrioIcon from '../Assets/Brio.svg'
import LinkdIn from '../Assets/LinkdIn.svg'
import facebook from '../Assets/facebook.svg'
import twiter from '../Assets/twiter(x).svg'
import youtube from '../Assets/youtube.svg'
import drop from '../Assets/dropdownicon.svg'
import Teams from '../Assets/Teams.svg';
import Word from '../Assets/Word.svg';
import Excel from '../Assets/Excel.svg';
import OneDrive from  '../Assets/OneDrive.svg';
import PowerPoint from '../Assets/PowerPoint.svg';
import SharePoint from '../Assets/SharePoint.svg';
import Exchange from '../Assets/Exchange.svg';
import Publisher from '../Assets/Publisher.svg';
import Access from '../Assets/Access.svg';
import OutLook from '../Assets/OutLook.svg';
import Perk from '../Assets/Perk.svg'
import AIP from '../Assets/AIP.svg'
import Intune from '../Assets/Intune.svg'
import buynow from '../Assets/buynow.svg';
import './dashboard.css';

const LandingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { error } = location.state || {};
    const [servicesPerPage, setServicesPerPage] = useState(4);
    const [services, setServices] = useState([]);
    const [otherServices, setOtherServices] = useState([]);
    const [addOnServices, setAddOnServices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOtherPage, setCurrentOtherPage] = useState(1);
    const [currentAddOnPage, setCurrentAddOnPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [showErrorNotification, setShowErrorNotification] = useState(!!error);
    const [activeIndex, setActiveIndex] = useState(null);
    const selectedCountry = location.state?.selectedCountry || {name: 'India',icon: '',};
    const  country_code = selectedCountry.name === "India" ? "IN" : selectedCountry.name === "USA" ? "US" : null;

    const faqs = [{
          question: "How does Brio Technologies ensures genuine Microsoft license?",
          answer:"As an authorized Microsoft Solution Partner, Brio Technologies provides genuine licenses directly sourced from Microsoft. This ensures compliance, proper activation, and full support for your business needs.",
          highlight: ["Microsoft Solution Partner", "genuine licenses"],
        },{
          question:"How will I activate my Microsoft 365 Business subscription after purchase?",
          answer:"Your Microsoft 365 Business subscription will be activated automatically as soon as your purchase is complete.",
          highlight: ["Microsoft 365 Business subscription", "activated automatically"],
        },{
          question:'What payment methods are accepted on Brio Technologies?',
          answer:'Brio Technologies accepts various payment methods, including credit/debit cards, net banking, UPI, and popular digital wallets.',
          highlight:[],
        },{
          question:'How can I renew my Microsoft 365 Business subscription?',
          answer:'You can renew your subscription directly through store.briotech.com. Log in to store.briotech.com, click your username in the top-right, and select Subscriptions. On the Subscriptions page, find your active subscription and click the Renew icon (↻). You can renew your subscription up to 30 days before the expiration date.',
          highlight:[],
        },{
            question:'In which country is this offer available?',
            answer:'This offer is available only for businesses registered in India.',
            highlight:[],
        }
      ];
    const cityList = [Hyderabad, Bengaluru, Chennai, DelhiNCR, Mumbai, Kochi, Vijayawada, DubaiUAE, RiyadhKSA, DelawareUS]

    useEffect(() => {
        const updateServicesPerPage = () => {
            if (window.matchMedia('(max-width: 560px)').matches) {
                setServicesPerPage(1); // For screens smaller than 400px
            } else if (window.matchMedia('(max-width: 825px)').matches){
                setServicesPerPage(2);
            } else {
                setServicesPerPage(3); // Default for larger screens
            }
        };

        updateServicesPerPage(); // Set initially
        window.addEventListener('resize', updateServicesPerPage); // Listen for resize events

        return () => window.removeEventListener('resize', updateServicesPerPage); // Clean up listener
    }, []);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('/dashboard', {
                    method: 'GET',
                    headers: {
                        'Authorization': `${token}`,
                    },
                });
                const data = await response.json();
                console.log(data,'data')
                setServices(data.featured_items);
                setOtherServices(data.other_items);
                setAddOnServices(data.add_on_items);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching services:', error);
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const getHighlightedText = (text, highlights) => {
        const regex = new RegExp(`(${highlights.join("|")})`, "gi"); // Match any word/phrase in the highlights
        const parts = text.split(regex);
        return parts.map((part, index) =>
          highlights.some((word) => word.toLowerCase() === part.toLowerCase()) ? (
            <span key={index} className="highlighted">
              {part}
            </span>
          ) : (
            part
          )
        );
      };
    

    const totalPages = Math.ceil(services.length / servicesPerPage);
    const indexOfLastService = currentPage * servicesPerPage;
    const indexOfFirstService = indexOfLastService - servicesPerPage;
    const currentServices = services.slice(indexOfFirstService, indexOfLastService);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

   
    const totalOtherPages = Math.ceil(otherServices.length / servicesPerPage);
    const indexOfLastOtherService = currentOtherPage * servicesPerPage;
    const indexOfFirstOtherService = indexOfLastOtherService - servicesPerPage;
    const currentOtherServices = otherServices.slice(indexOfFirstOtherService, indexOfLastOtherService);

    const paginateOtherServices = (pageNumber) => setCurrentOtherPage(pageNumber);


    const totalAddOnPages = Math.ceil(addOnServices.length / servicesPerPage);
    const indexOfLastAddOnService = currentAddOnPage * servicesPerPage;
    const indexOfFirstAddOnService = indexOfLastAddOnService - servicesPerPage;
    const currentAddOnServices = addOnServices.slice(indexOfFirstAddOnService, indexOfLastAddOnService);

    const paginateAddOnServices = (pageNumber) => setCurrentAddOnPage(pageNumber);
    const service_icons = {
        "Teams": Teams,
        "Word": Word,
        "Excel": Excel,
        "OneDrive": OneDrive,
        "PowerPoint": PowerPoint,
        "SharePoint": SharePoint,
        "OutLook": OutLook,
        "Exchange": Exchange,
        "Publisher": Publisher,
        "Access": Access,
        "Perk":Perk,
        "Intune":Intune,
        "AIP":AIP,
    };
    const BusinessEssential_icons = ["Teams", "Word", "Excel", "OneDrive", "PowerPoint", "OutLook", "Exchange", "Perk"]
    const handleBuyNow = () => {
        navigate('/order', { state: { sku_id: "CFQ7TTC0JN4R:0002", country: country_code } });
        window.scrollTo(0, 0);
    };
    const handleExploreMore = () => {
        window.scrollBy({
            top: 600,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="LPApp">
        <Navbar />
            {loading && (
            <div className="modal-overlay">
                <div className="preloader-modal">
                    <div className="spinner"></div>
                </div>
            </div>
        )}
            <div className="DashMainContainer" style={{marginTop:'0'}}>
                <div className="LPServices">
                    <div className="LPCardContainer banner" style={{backgroundImage:`url(${banerBackground})`}}>
                            <div className="bannerContent">
                                <p style={{fontSize:'1rem', fontWeight:'bold', marginBottom:'10px'}}>Microsoft 365 – Where Innovation Meets Productivity! And Transform the Way You Work with Microsoft 365 - Seamless, Secure and Smart!</p>
                                <h3 style={{fontSize:'1.6rem', fontWeight:'bold', margin:'10px 0 20px', lineHeight:'1.5'}}> Don’t Miss Out! Save Big on Business Email</h3>
                                <div style={{width:'20%', height:'4px', backgroundColor:'#FFF200', marginBottom:'20px'}}></div>
                                <p style={{fontSize:'1rem', marginBottom:'20px'}}>
                                    <span style={{color:'#FFF200', fontWeight:'bold'}}>Unlock the Productivity with same Microsoft 365 you know and love.</span>
                                </p>
                                <p style={{fontSize:'1.5rem', marginBottom:'20px'}}>
                                    <span>Starting at just ₹ 79 per Month </span>
                                </p>
                                <div className="row">
                                    {BusinessEssential_icons.map((img, index) =>
                                        <div
                                            key={`${index}`}
                                            className="feature"
                                            style={{
                                                width: img ? "12%" : "100%",
                                                padding: img ? '3%' : '0'
                                            }}
                                            >
                                            <img
                                                src={service_icons[img]}
                                                alt={img}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='row banner-button'>
                                    <div className="frame-getstarted" onClick={handleBuyNow}>
                                        <span className="buy-now">Get Started</span>
                                        <img src={buynow} alt="buy now" />
                                    </div>
                                    <div className="frame-getstarted" onClick={handleExploreMore}>
                                        <span className="buy-now">Explore More</span>
                                        <img src={buynow} alt="Explore More" />
                                    </div>
                                </div>
                                {/* <p></p> */}
                            </div>
                    </div>
                </div>
                <div className="LPFeaturedServices">
                    <div className="LPServicesHeading">
                        <span>Featured Services</span>
                        <p>Select from our featured services catered for every business.</p>
                    </div>

                    <div className="LPCardContainer">
                        {currentServices.map((service, index) => (
                            <Card key={index} service={service} />
                        ))}
                    </div>
                    <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
                </div>


                <div className="LPFeaturedServices">
                    <div className="LPServicesHeading">
                        <span>Other Services</span>
                        <p>Select from our other services that businesses around the world are using.</p>
                    </div>

                    <div className="LPCardContainer">
                        {currentOtherServices.map((service, index) => (
                            <Card key={index} service={service} />
                        ))}
                    </div>
                    <Pagination totalPages={totalOtherPages} currentPage={currentOtherPage} paginate={paginateOtherServices} />
                </div>
                {currentAddOnServices.length > 0 && (
                <div className="LPFeaturedServices">
                    <div className="LPServicesHeading">
                        <span>Add-On Services</span>
                        <p>Explore additional services that enhance your experience.</p>
                    </div>

                    <div className="LPCardContainer">
                        {currentAddOnServices.map((service, index) => (
                            <Card key={index} service={service} is_addon={true} />
                        ))}
                    </div>
                    <Pagination totalPages={totalAddOnPages} currentPage={currentAddOnPage} paginate={paginateAddOnServices} />
                </div>
                )}
                <footer>
                    <div className="faq-section">
                        <div style={{maxWidth:'350px'}}>
                            <h2 className="faq-heading">Frequently Asked Questions</h2>
                        </div>
                        <div className="faq-box">
                        {faqs.map((faq, index) => (
                            <div key={index} className={`faq-item ${activeIndex === index ? "active" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFaq(index)}>
                                    {faq.question}
                                    <span className="faq-icon">
                                        {activeIndex === index ? (
                                            <>
                                                <img src={drop} alt="" />
                                            </>
                                                ) : (
                                            <>
                                                <img src={drop} alt="" />
                                            </>)}
                                    </span>
                                </div>
                                <div className="faq-answer" style={{ display: activeIndex === index ? "block" : "none" }}>
                                    {getHighlightedText(faq.answer, faq.highlight)}
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="footer-top p-3">
                        <div className="footer-cities">
                            {cityList.map((city, index) => (
                                <img key={index} src={city} className="city-icon p-3" alt="City Icon" />
                            ))}
                        </div>
                    </div>
                    <div className="footer-bottom p-5">
                        <div className='footer-logo'>
                            <div className='company-logo'>
                                <img src={BrioIcon} alt="Brio Technology" />
                            </div>
                            <div className='socials'>
                                <div className="company">
                                    <span style={{color:'#9A9A9A'}}>© 2024 Briotech.</span>
                                </div>
                                <div className='socials-link' >
                                    <a href="https://www.linkedin.com/company/brio-technologies-private-limited/?originalSubdomain=in">
                                        <img src={LinkdIn} alt="LinkedIn" />
                                    </a>
                                    <a href="https://twitter.com/briotech">
                                        <img src={twiter} alt="X (Twitter)" />
                                    </a>
                                    <a href="https://www.facebook.com/briohyd/">
                                        <img src={facebook} alt="Facebook" />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCfTwtfubl7te-VOOZr-so_g">
                                        <img src={youtube} alt="YouTube" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='contacts'>
                            <span className='second-header'>Contact</span>
                            <div className="section">
                                <div className="contact-link">
                                    <span style={{marginBottom:'10px'}}>India</span>
                                    <span className='link'>Sales: <a href="tel:+918125002746">+91 81250 02746</a> </span>
                                    <span className="link">Support: <a href="tel:+918099772746">+91 80997 72746</a></span>
                                    <span className="link">Billing/Others: <a href="tel:+914067661500">+91 40 6766 1500</a></span>
                                </div>
                                <div className="contact-link">
                                    <span style={{marginBottom:'10px'}}>USA</span>
                                    <span className='link'>Sales: <a href="tel:+18582752746">+1 858 275 2746</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                {showErrorNotification && (
                    <div className='error-msg-notification-container'>
                        <Notification 
                            message={error} 
                            onClose={() => {
                                setShowErrorNotification(false);
                                navigate('.', { replace: true, state: { ...location.state, error: undefined } });
                            }} 
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LandingPage;
