import React, { useEffect, useState } from 'react';
import { FaExclamationCircle, FaCheckCircle, FaTimes } from 'react-icons/fa';

const Notification = ({ message, onClose, type = "error" }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // Set up the timer to start fading out the notification after 5 seconds
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, 15000);

        // Call onClose after the fade-out animation completes
        const fadeOutTimer = setTimeout(() => {
            onClose();
        }, 5500); // 500ms longer to account for fade-out duration

        // Clean up the timers if the component is unmounted before they complete
        return () => {
            clearTimeout(timer);
            clearTimeout(fadeOutTimer);
        };
    }, [onClose]);

    return (
        <div className={`notification ${type} ${isVisible ? 'fade-in' : 'fade-out'}`}>
            <div>
            {type === "success" ? (
                <FaCheckCircle className="notification-icon success-icon" />
            ) : (
                <FaExclamationCircle className="notification-icon error-icon" />
            )}
            </div>
            <span className="notification-text" style={{padding:'0% 4% 0% 2%'}}>{message}</span>
            <div>

            <button className="notification-close" onClick={() => {
                setIsVisible(false);
                onClose();
            }}>
                <FaTimes />
            </button>
            </div>
        </div>
    );
};

export default Notification;
