import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './MicrosoftSignIn.css';
import noMsicon from '../Assets/noMsicon.svg';
import yesMsicon from '../Assets/yesMsicon.svg';
import buynow from '../Assets/buynow.svg';
import correcticon from '../Assets/correct.svg'
import xmark from '../Assets/xmark.svg'
import info from '../Assets/info.svg'
import Notification from '../../components/Notification';
import axios from 'axios';
import msicon2 from '../Assets/msicon2.svg';
import Card from '../../components/itemCard';

const MicrosoftSignIn = ({ onAction, countryData }) => {
  const [selectedOption, setSelectedOption] = useState(1);
  const { id } = useParams();
  const [country, setCountry] = useState(countryData?.country || '');
  const token = localStorage.getItem('token');
  const currentUser = localStorage.getItem('user')
  const [domain, setDomain] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('*');
  const [orderData, setOrderData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [type, setType] = useState('info');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState(null);
  const [invite, setInvite] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [msEmail, setMsEmail] = useState('');
  const userId = localStorage.getItem('userId');
  const [popupUrl, setPopupUrl] = useState('https://go.microsoft.com/fwlink/?linkid=2081615&clcid=0x409&culture=en-us&country=in')

  useEffect(() => {
    // If country is not defined, fetch from backend
    if (!country) {
      const fetchCountry = async () => {
        try {
          const response = await fetch(`/get/stage?id=${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
            },
        });
          const data = await response.json();
          const fetchedCountry = data.console === 'UP' ? 'US' : data.console === 'IP' ? 'IN' : '';
          setPopupUrl(data.other)
          setCountry(fetchedCountry);
        } catch (error) {
          console.error('Error fetching country:', error);
        }
      };

      fetchCountry();
    }
  }, [country, id]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const currency = country === "IN" ? "INR" : country === "US" ? "USD" : null;
  const country_code = country === "IN" ? "IN" : country === "US" ? "US" : null;
  const ms_console = country === "IN" ? "IP" : country === "US" ? "UP" : null;

  const handleDomainChange = (e) => {
    const inputDomain = e.target.value;
    setDomain(inputDomain);
    
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(async () => {
        setIsLoading(true);
        try {
          const response = await fetch(`/mssignup?tenant=${inputDomain}&console=${ms_console}`, {
            method: 'GET',
            headers: {
              'Authorization': `${token}`,
            },
          });
          const data = await response.json();
          if (data.valid === true) {
            setError('');
          } else {
            setError('domain is not available');
          }
        } catch {
          setError('an error occurred while validating the domain');
        } finally {
          setIsLoading(false);
        }
      }, 500) // 500ms delay
    );
  };

  const handleUserChange = (e) => {
    const inputUser = e.target.value;
    setUsername(inputUser);
  };

  const handleExistingUser = (e) => {
    const value = e.target.value;
    // Check if the input ends with '.onmicrosoft.com'
    if (value.endsWith('.onmicrosoft.com')) {
      const splitParts = value.split('@');
      if (splitParts.length === 2) {
        const localPart = splitParts[0];
        const domainPart = splitParts[1].replace('.onmicrosoft.com', ''); // 'domain' part
        setUsername(localPart);
        setDomain(domainPart);
        setIsValid(true);
        setMsEmail(value);
      } else {
        setIsValid(false); // Invalid if it doesn't split correctly
      }
    } else {
      setIsValid(false); // Not valid if it doesn't match the pattern
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`/placeorder?id=${id}`, {
          method: 'GET',
          headers: {
              'Authorization': `${token}`,
          },
      });
        const data = await response.json();
        setOrderData(data);
        setUsername(data.order_details.order.properties.firstname+data.order_details.order.properties.lastname)
        if (data.order_details.order.invitation_accepted === true){
          setInvite(true)
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [id]);

  useEffect(() => {
    const fetchDomain = async () => {
      try {
        setLoading(true)
        const response = await fetch(`/api/domain`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
          },
          body: JSON.stringify({"console":ms_console, "customer":orderData.customer_name}),
        });
        const data = await response.json()
        console.log(data)
        setDomain(data[0])
        setLoading(false)
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    }
    
      fetchDomain()
  }, [orderData, country])

  const handleNextClick = async (existingCustomer, cust_uid) => {
    if (!orderData) return;
    const is_existing_customer = cust_uid?"true":"false"
    const formattedData = {
      account_id: orderData.order_details.order.accountid,
      customer_name: orderData.customer_name,
      customerid: orderData.customerid || orderData.order_details.order.customerid || null,
      is_existing_customer:is_existing_customer,
      isCustomer:orderData.order_details.order.properties.isCustomer,
      new_address:orderData.order_details.order.properties.new_address,
      o365_uid:cust_uid,
      addressInfo: {
        add1: orderData.order_details.order.properties.addres[0],
        add2: orderData.order_details.order.properties.addres[1],
        city: orderData.order_details.order.properties.city,
        state: orderData.order_details.order.properties.state,
        pincode: orderData.order_details.order.properties.pincode,
        country: orderData.order_details.order.properties.country,
        gst_code:orderData.order_details.order.shipping_address.gst_code,
        organization_registration_number: orderData.order_details.order.properties.organisationRegistrationNumber,
      },
      contactInfo: {
        first_name: orderData.order_details.order.properties.firstname,
        last_name: orderData.order_details.order.properties.lastname,
        email: orderData.order_details.order.properties.email,
        phone: orderData.order_details.order.properties.phone,
      },
      current_user:currentUser,
      console: ms_console,
      tenantname: domain,
      organization_registration_number: orderData.order_details.order.properties.organisationRegistrationNumber,
      username: username,
      country_code: country_code,
      currency: currency,
      quantity: orderData.quantity,
      unit_price: orderData.unit_price,
      plan: orderData.order_details.order.order_line_items[0].commitment,
      billing_plan: orderData.order_details.order.order_line_items[0].billing,
      itemid: orderData.order_details.order.order_line_items[0].id,
      offerid: orderData.order_details.order.order_line_items[0].catalogItemId,
      order_id: orderData.order_details.order.id,
      userId: userId,
    };
    if (!orderData.customerid && !orderData.order_details.order.customerid) {
      delete formattedData.customerid;
    }

    try {
      setLoading(true);
      setLoadingMessage("We are adding product to your account. This may take a minute.")
      const response = await fetch('/mssignup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify(formattedData),
      });
      const result = await response.json();
      console.log(result);
      if (!result) {
        console.error('No result returned from the server.');
        return false;
      }
      if(result.status === "Error"){
        setLoading(false);
        setMessage('Something went wrong. Please try again after sometime or contact support. Exception: '+result.message);
        setType("error")
        setShowMessage(true)
        setIsDisabled(false)
        return false
      }
      localStorage.setItem('customerId', JSON.stringify(result.customerid));
      setLoading(false);
      onAction();
    } catch (error) {
      console.error('Error during signup:', error);
      setLoading(false);
      setMessage('Something went wrong. Please try again after sometime or contact support. Exception: '+error);
      setType("error")
      setShowMessage(true)
      setIsDisabled(false)
    }
  };
  const openPopup = () => {
    let popup = window.open(
      popupUrl, // Replace with actual URL
      '_blank',
      'width=600,height=600'
    );
    setMessage('We are checking if you approved the Microsoft partner relationship request. This will take a minute');
    setType("info")
    setShowMessage(true)
    setIsDisabled(true)
    setIsLoading(true)
  
    // Set timeout to close the popup after 10 minutes (600000ms)
    const timeoutId = setTimeout(() => {
      if (popup && !popup.closed) {
        popup.close();
        clearTimeout(timeoutId); // Clear timeout when response is received
        clearInterval(intervalId); // Stop polling when a response is received
        setMessage('We did not receive a Microsoft partner relationship approval for this username\'s account. If you have an account, please try again below and retype your current account\'s username. If you do not have an account, please select "I do not have a Microsoft 365 account" to create an account');
        setType("error")
        setShowMessage(true)
        setIsDisabled(false)
        setIsLoading(false)
        console.log('Popup closed after 10 minutes of no response.');
      }
    }, 600000);
  
    const checkWebhookStatus = async () => {
      try {
        const response = await axios.get(`/reseller/invitation/status?id=${id}&email=${msEmail}`); // Modified endpoint
        const data = response.data;
  
        if (data.cached_data && data.cached_data.invitation_accepted === true) {
          clearTimeout(timeoutId);
          clearInterval(intervalId);
          if (popup && !popup.closed) popup.close();
          setLoading(true)
          setIsLoading(false)
          setInvite(true)
          handleNextClick("true", data.cached_data.o365_uid)
        }
      } catch (error) {
        console.error('Error fetching webhook status:', error);
      }
    };

    const intervalId = setInterval(checkWebhookStatus, 5000);
  
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  };
  const handleNotificationClose = () => {
    setNotificationMessage('');
  };
  const handleItemPopup = async () => {
    try {
        const token = localStorage.getItem('token');
        const sku = localStorage.getItem('item')
        const response = await fetch(`/item?sku=${sku}`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
            },
        });
        const data = await response.json();
        setService(data); // Store the service data
        setShowModal(true); // Show the modal
    } catch (error) {
        console.error('Error fetching item details:', error);
    }
};
const handleCloseModal = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
        setShowModal(false); // Close the modal if clicking outside the card
    }
};

  const getIcon = () => {
    if (type === 'error') return xmark;
    if (type === 'info') return info;
    return null;
  };

  return (
    <div className='maindiv'>
      {loading && (
        <div className="modal-overlay">
          <div className="preloader-modal">
            <div className="spinner"></div>
            <p className="loading-message">{loadingMessage}</p>
          </div>
        </div>
      )}
      <div className="PFServicesHeading">
        <div className='ItemDetails-heading'>
          <span>Microsoft Sign-In</span>
          {/* <button className="view-plan-button" onClick={handleItemPopup}>
              <img src={msicon2} alt="service-icon" className="ms-icon" />
              View Item Details
          </button> */}
        </div>
        <p>Select how you prefer to sign-in to Microsoft 365</p>
      </div>
      <div className="mainContainer">
        <div className="contentContainer">
          <div className="questionBox">
            <p>Do you have a Microsoft 365 Account which you want to add to this subscription?</p>
            <div className={`optionsContainer ${isDisabled ? "waittime-disabled" : ""}`}>
              <div
                className={`optionBox ${selectedOption === 1 ? 'selected' : ''}`}
                onClick={() => handleOptionClick(1)}
              >
                <img src={noMsicon} alt="No Microsoft Account" />
                <p>I don't have a Microsoft account or use any Microsoft services like Power BI, 365, Azure, etc.</p>
              </div>
              <div
                className={`optionBox ${selectedOption === 2 ? 'selected' : ''}`}
                onClick={() => handleOptionClick(2)}
              >
                <img src={yesMsicon} alt="Yes Microsoft Account" />
                <p>I have a Microsoft account and want to add this subscription to my existing account.</p>
              </div>
            </div>
          </div>
          {showMessage && (
            <div className={`message-box ${type}`}>
              <div className="message-icon">
                <img src={getIcon()} alt={`${type} icon`} className={`icon-${type}`} />
              </div>
              <p>{message}</p>
            </div>
          )}
          {selectedOption === 1 ? (
            <>
              <div className='additoinalinfoHeading'>How you'll sign in to Microsoft 365</div>
              <div className="additionalInfo">
                This username is what you'll use to sign in each time you use your Microsoft 365 subscription.
                The domain name is a suggestion. You can change your domain now, or later at any time with
                your own custom domain.
              </div>
              <div className="formContainer">
                <div className="inputGroup">
                  <label htmlFor="username">Username</label>
                  <div className="domainInputWrapper">
                    <input
                      type="text"
                      id="username"
                      placeholder="Adam"
                      value={username}
                      onChange={handleUserChange}
                      style={{ border: 'none', outline: 'none' }}
                    />
                  </div>
                </div>
                <div className="inputGroup">
                  <label htmlFor="domain">Domain Name</label>
                  <div className="domainInputWrapper">
                    <input
                      type="text"
                      id="domain"
                      value={domain}
                      style={{ border: 'none', WebkitTextDecorationLine: 'none', outline: 'none' }}
                      onChange={handleDomainChange}
                      placeholder="Enter domain name"
                    />
                    {isLoading ? (
                      <div className="input-spinner"></div>
                    ) : (
                      (!error || error === '*') && <img src={correcticon} alt="correct" />
                    )}
                    <span className="fixedPlaceholder">.onmicrosoft.com</span>
                  </div>
                  {error && <p className="errorText">{error}</p>}
                </div>
              </div>
              <div className="MS-frame26cont" style={{justifyContent:'flex-end'}}>
            <div className="MS-frame26" onClick={handleNextClick}>
            <span>Next</span>
            <img src={buynow} alt="Buy Now" />
            </div>
          </div>
            </>
          ) : (
            <>
            <div className= {isDisabled ? "waittime-disabled" : ""}>
              <div className="subscription-instructions">
                <h2>Add subscription to your existing Microsoft account</h2>
                <p>
                  To add a subscription to your existing Microsoft account, you'll need to authorize a Microsoft partner relationship with Contoso:
                </p>
                <ul style={{ paddingLeft: '15px' }}>
                  <li style={{ marginLeft: 0 }}>Enter your existing Microsoft username below</li>
                  <li style={{ marginLeft: 0 }}>Click next to login to your Microsoft account</li>
                  <li style={{ marginLeft: 0 }}>Authorize the new partner relationship in Microsoft admin center</li>
                </ul>
                <p>
                  When you click the "Next" button, a new tab will open. You'll be asked to give us permission to become your Microsoft Partner Reseller.
                </p>
                <p>
                  Once you've given us permission, we will automatically complete your purchase.
                </p>
              </div>
              <div className="formContainer">
                <div className="inputGroup">
                  <label htmlFor="username">Enter your existing Microsoft username</label>
                  <input
                  type="text"
                  id="username"
                  placeholder="admin@domain.onmicrosoft.com"
                  onChange={handleExistingUser}
                />
                </div>
              </div>
            </div>
          <div className="MS-frame26cont" style={{ justifyContent: isDisabled ? 'space-between' : 'flex-end' }} >
            {isDisabled && (<div className='MS-frame29cont'><span className='link-text'>Not seeing a new tab open?</span> <a className='link-text' href={popupUrl} target="_blank" rel="noopener noreferrer">Click here to authorize the partner relationshiip</a> </div>)}  
            <div className={`MS-frame26 ${isDisabled ? "waittime-disabled" : ""} ${!isValid ? "waittime-disabled" : ""}`} onClick={invite ? handleNextClick : openPopup}>
              {isLoading ? (
                <>
                  <div className="button-spinner"></div>
                  <img src={buynow} alt="Buy Now" />
                </>
              ) : (
                <>
                  <span>Next</span>
                  <img src={buynow} alt="Buy Now" />
                </>
              )}
            </div>
          </div>
          </>
          )}
        </div>
      </div>
      <div className="notification-container">
      {notificationMessage && (
        <Notification message={notificationMessage} onClose={handleNotificationClose} />
      )}
      {showModal && (
          <div className="modal-overlay" onClick={handleCloseModal}>
              {service && <Card service={service} type='preview' />}
          </div>
      )}
      </div>
    </div>
  );
};

export default MicrosoftSignIn;
