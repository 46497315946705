import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Teams from '../Assets/Teams.svg';
import Word from '../Assets/Word.svg';
import Excel from '../Assets/Excel.svg';
import OneDrive from  '../Assets/OneDrive.svg';
import PowerPoint from '../Assets/PowerPoint.svg';
import SharePoint from '../Assets/SharePoint.svg';
import Exchange from '../Assets/Exchange.svg';
import Publisher from '../Assets/Publisher.svg';
import Access from '../Assets/Access.svg';
import OutLook from '../Assets/OutLook.svg';
import Perk from '../Assets/Perk.svg'
import AIP from '../Assets/AIP.svg'
import Intune from '../Assets/Intune.svg'
import doticon from '../Assets/doticon.svg';
import buynow from '../Assets/buynow.svg';
import MicrosoftBusinessEssentials from '../Assets/BusinessEssentials.png'
import DefaultO365Image from '../Assets/defalut-office365.svg' 
import BusinessBasic from '../Assets/BusinessBasic.png'
import BusinessStandard from '../Assets/BusinessStandard.png'
import BusinessPremium from '../Assets/BusinessPremium.png'
import './dashboard.css';

function Card({ service, type = '', is_addon=false }) {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedCountry = location.state?.selectedCountry || { name: 'India', icon: "" };
    const queryParams = new URLSearchParams(location.search);
    const TUPValue = queryParams.get('TUP');
    const country_code = selectedCountry.name === "India" ? "IN" : selectedCountry.name === "USA" ? "US" : null;
    const currency = country_code === "IN" ? "INR" : country_code === "US" ? "USD" : null;
    const currencySymbols = {"INR": "₹","USD": "$"};
    const price_dict = service.price;
    const price = service.price[currency];
    const currencySymbol = currencySymbols[currency];
    const [isAuthenticated, setIsAuthenticated] = useState()
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);
    const handleBuyNow = () => {
        localStorage.setItem("item", service.sku)
        if (TUPValue === "true"){
            navigate('/contract', { state: { sku_id: service.sku, country: country_code } });
        } else if (is_addon == true || service.sku == "CFQ7TTC0MM8R:0002"){
            navigate('/subscription/addon', { state: { sku_id: service.sku, country: country_code } });
        } else{
            navigate('/order', { state: { sku_id: service.sku, country: country_code } });
        }
        window.scrollTo(0, 0);
    };
    const service_icons = {
        "BusinessEssentials": MicrosoftBusinessEssentials,
        "businessBasic": BusinessBasic,
        "businessStandard": BusinessStandard,
        "businessPremium": BusinessPremium,
        "Teams": Teams,
        "Word": Word,
        "Excel": Excel,
        "OneDrive": OneDrive,
        "PowerPoint": PowerPoint,
        "SharePoint": SharePoint,
        "OutLook": OutLook,
        "Exchange": Exchange,
        "Publisher": Publisher,
        "Access": Access,
        "Perk":Perk,
        "Intune":Intune,
        "AIP":AIP,
    };
    return (
        <div className="card">
            <div className="card-header-1">
                <h3 className="serviceTitle">{service.name}</h3>                
            </div>
            {type !== 'preview' && (
                    <div className="frame-24">
                        <div className="frame-65">
                            <span className="starting-at">Starting at</span>
                            <div className='erp-price'>
                            {price_dict['ERP_INR'] && (<div style={{'color':'#4F4F4F', 'textDecorationLine':'line-through', fontSize:'17px',}} className="price">{currencySymbol}{price_dict['ERP_INR']}</div>)}
                            <span className="price">{currencySymbol}{price}/Monthly</span>
                            </div>
                        </div>
                        <div className="frame-26" onClick={handleBuyNow}>
                            <span className="buy-now">Buy Now</span>
                            <img src={buynow} alt="buy now" />
                        </div>
                    </div>
                )}
            <div className='descriptionHeading'>{service.description}:</div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div className="Descriptions">
                    {service.features.map((description, index) => (
                        <div key={index} className='description'>
                            <img src={doticon} alt="dot icon" />
                            <span>{description}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{margin:'0px', textAlign:'left'}}>
                <p style={{color:'#9A9A9A', margin:'0px 0px 0px 10px'}}>Includes:</p>
            </div>
            <div className="features">
            {service.images.map((img, index) =>
                // (service_b_icons[feature] || ["Default"]).map((img, imgIndex) => (
                    <div
                    key={`${index}`}
                    className="feature"
                    style={{
                        width: service_icons[img] ? "16%" : "100%",
                        padding: service_icons[img] ? '3%' : '0'
                    }}
                    >
                    <img
                        src={service_icons[img] ? service_icons[img] : DefaultO365Image}
                        alt={img}
                    />
                    </div>
                // ))
            )}
            </div>
        </div>
    );
}

export default Card;
