import React, { useState } from "react";
import '../Subscription.css'; // Assuming this contains global styles

const GSTModal = ({ showModal, onClose, onSubmit, customerAddres = [] }) => {
    const [hasGstNumber, setHasGstNumber] = useState(true);
    const [gstNumber, setGstNumber] = useState("");
    const [selectedGstCode, setSelectedGstCode] = useState("");

    const handleSubmit = () => {
        if (selectedGstCode) {
            onSubmit({ gst: selectedGstCode, selectedAddress: true });
        } else if (hasGstNumber) {
            onSubmit({ gst: gstNumber, selectedAddress: false });
        } else {
            onSubmit({ manual: true, selectedAddress: true });
        }
        onClose();
    };

    if (!showModal) {
        return null;
    }

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="radio-group">
                    <label className="GSTradio-lable">
                        <input 
                            type="radio" 
                            name="gstOption" 
                            className="radioB "
                            value="hasGst" 
                            checked={hasGstNumber && !selectedGstCode}
                            onChange={() => {
                                setHasGstNumber(true);
                                setSelectedGstCode("");
                            }}
                        />
                        <span style={{cursor:'pointer'}}>
                        I have a GST number
                        </span>
                    </label>
                    {hasGstNumber && !selectedGstCode && (
                        <div className="input-wrapper">
                            <input
                                type="text" 
                                name="GST-input"
                                className="GSTinput-field" 
                                placeholder="Enter your GST number to auto-fill the form." 
                                value={gstNumber}
                                onChange={(e) => setGstNumber(e.target.value)}
                            />
                        </div>
                    )}
                    <label className="GSTradio-lable">
                        <input 
                            type="radio" 
                            name="gstOption" 
                            className="radioB"
                            value="noGst" 
                            checked={!hasGstNumber && !selectedGstCode}
                            onChange={() => {
                                setHasGstNumber(false);
                                setSelectedGstCode("");
                                setGstNumber("");
                            }}
                        />
                        <span style={{cursor:'pointer'}}>
                        I don't have a GST number, I will add details manually
                        </span>
                    </label>
                    {customerAddres.map((address, index) => (
                        <label key={index} className="GSTradio-lable" style={{display:'flex'}}>
                            <div>
                            <input
                                type="radio"
                                name="gstOption"
                                className="radioB"
                                value={address.gst_code}
                                checked={selectedGstCode === address.gst_code}
                                onChange={() => {
                                    setSelectedGstCode(address.gst_code);
                                    setHasGstNumber(false);
                                    setGstNumber("");
                                }}
                                />
                            </div>
                            <div>
                                <span style={{cursor:'pointer'}}>
                                {address.gst_code}, ({address.add1}, {address.city}, {address.state}, {address.pincode})
                                </span>
                            </div>
                        </label>
                    ))}
                </div>
                <div className="modal-buttons">
                    <button 
                        className="button button-proceed" 
                        onClick={handleSubmit}
                        disabled={hasGstNumber && !gstNumber && !selectedGstCode}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GSTModal;
