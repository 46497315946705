import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import S3PaymentModal from "../PurchaceFlow/S3PaymentModel";
import { handlePayment } from "../razorpay";
import "../PurchaceFlow/purchaceFlow.css";
import axios from "axios";
import msicon2 from "../Assets/msicon2.svg";
import Card from "../../components/itemCard";
import Notification from "../../components/Notification";

const ReviewPlaceOrder = ({ onAction, countryData }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { id } = useParams();
  const [country, setCountry] = useState(countryData?.country || ""); // Initialize with countryData or empty string
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [provision, setProvision] = useState(false);
  const [captureDetails, setCaptureDetails] = useState(null);
  const [retryUrl, setRetryUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [service, setService] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [razorpayId, setRazorpayId] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [provisionFail, setProvisionFail] = useState('');
  const currentUser = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("firstname");
  const email = localStorage.getItem("user");
  const phone = localStorage.getItem("phone");
  const customer_name = localStorage.getItem("customerName");
  const country_code = country === "IN" ? "IN" : country === "US" ? "US" : null;
  const ms_console = country === "IN" ? "IP" : country === "US" ? "UP" : null;
  const currency = country === "IN" ? "INR" : country === "US" ? "USD" : null;
  const currencySymbols = { INR: "₹", USD: "$" };

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await fetch(`/get/stage?id=${id}`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        });
        const data = await response.json();
        console.log(data);
        if (data.console){
        if (data.console === "UP") {
          setCountry("US");
        } else if (data.console === "IP") {
          setCountry("IN");
        }
        }
        setRazorpayId(data.razorpay)
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

      fetchCountryData();
  }, [id, country]);


  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`/placeorder?id=${id}`, {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        });
        const data = await response.json();
        setOrderDetails(data);
        if (data.order_details.order.payment_status === 'completed'){
          const payment_data = { payment_details:data.order_details.order.payment_details,
            data:{
            id: id,
            email: email,
            first_name: name,
            phone: phone,
            totalAmount: data.grand_total,
            isAuthenticated: true,
            payment_data:{}
          }}
          setRetryUrl(`/api/capturepayment`)
          setCaptureDetails(payment_data)
          setShowRetryButton(true)
          setProvision(true)
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const fetchCustomer = async () => {
    try {
        let customerid = orderDetails.order_details.order.customerid;
        const response = await fetch(`/api/get/cust?id=${customerid}`, {
            method: "GET",
            headers: {
                Authorization: `${token}`,
            },
        });
        const data = await response.json();
        return data.name;
    } catch (error) {
        console.error("Error fetching customer data:", error);
    }
};

  const handleNextClick = async (existingCustomer, cust_uid) => {
    if (!orderDetails) return;
    const is_existing_customer = cust_uid ? "true" : "false";
    const is_new_contract = cust_uid ? "true" : "false";
    const customerName = await fetchCustomer();
    const formattedData = {
      account_id: orderDetails.order_details.order.accountid,
      customer_name: customer_name || customerName,
      customerid: orderDetails.customerid,
      is_existing_customer: is_existing_customer,
      o365_uid: cust_uid,
      is_new_contract: is_new_contract,
      contactInfo: {
        first_name: orderDetails.order_details.order.properties.firstname,
        last_name: orderDetails.order_details.order.properties.lastname,
        email: orderDetails.order_details.order.properties.email,
        phone: orderDetails.order_details.order.properties.phone,
      },
      current_user: currentUser,
      console: ms_console,
      tenantname: orderDetails.order_details.order.properties.tenant,
      organization_registration_number:
        orderDetails.order_details.order.properties
          .organisationRegistrationNumber,
      country_code: country_code,
      currency: currency,
      quantity: orderDetails.quantity,
      unit_price: orderDetails.unit_price,
      order_line_items:orderDetails.order_details.order.order_line_items,
      plan: orderDetails.order_details.order.order_line_items[0].commitment,
      billing_plan: orderDetails.order_details.order.order_line_items[0].billing,
      itemid: orderDetails.order_details.order.order_line_items[0].id,
      offerid: orderDetails.order_details.order.order_line_items[0].catalogItemId,
      order_id: orderDetails.order_details.order.id,
    };

    try {
      const response = await fetch("/mssignup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(formattedData),
      });
      const result = await response.json();
      console.log(result);
      if (result.status == 'Error'){
        setNotificationMessage('Something went wrong. Please try again after sometime or contact support. Exception: '+result.message);
        handlePaymentFailure(JSON.stringify(formattedData), result, '/mssignup', true)
      } else {
        onAction({itemName:orderDetails.name});
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setNotificationMessage('Something went wrong. Please try again after sometime or contact support. Exception: '+error);
      handlePaymentFailure(JSON.stringify(formattedData), error, '/mssignup', true)
    } finally {
    }
  };

  const handlePaymentFailure = (details, message, url, provision = false) => {
    console.error(message);
    setCaptureDetails(details);
    setRetryUrl(url);
    setShowRetryButton(true);
    setLoading(false);
    setProvisionFail(provision)
  };

  const handlePlaceOrderClick = () => {
    debugger;
    if (isChecked) {
      const payment_data = {
        id: id,
        email: email,
        first_name: name,
        phone: phone,
        totalAmount: orderDetails.grand_total,
        tax_price: orderDetails.tax_amount,
        isAuthenticated: true,
        payment_data:{}
      };
      // setLoading(true);
      setLoadingMessage(
        "We are adding product to your account. This may take a minute."
      );
      handlePayment(
        handlePaymentSuccess,
        handlePaymentFailure,
        "signUp",
        payment_data,
        setLoading,
        razorpayId
      );
    }
  };

  const handleItemPopup = async () => {
    try {
      const token = localStorage.getItem("token");
      const sku = localStorage.getItem("item");
      const response = await fetch(`/item?sku=${sku}`, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      const data = await response.json();
      setService(data); // Store the service data
      setShowModal(true); // Show the modal
    } catch (error) {
      console.error("Error fetching item details:", error);
    }
  };
  const handleCloseModal = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setShowModal(false); // Close the modal if clicking outside the card
    }
  };

  const handleRetryClick = async () => {
    if (captureDetails) {
      setLoading(true);
      try {
        if (provisionFail){
          handleNextClick("true", orderDetails.order_details.order.properties.uid);
        } else{
          const result = await axios.post(retryUrl, captureDetails);
          if (result.data.status) {
            if (provision == true) {
              handleNextClick("true", orderDetails.order_details.order.properties.uid);
            } else{
              onAction();
            }
          } else {
            console.error("Retry failed: Backend validation failed.");
          }
        }
      } catch (error) {
        console.error("Retry failed:", error);
      }
    }
  };

  const handleNotificationClose = () => {
    setNotificationMessage('');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handlePaymentSuccess = (response) => {
    handleNextClick("true", orderDetails.order_details.order.properties.uid);
  };

  if (!orderDetails) {
    return (
      <div className="modal-overlay">
        <div className="preloader-modal">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  console.log(orderDetails.unit_price, currency);
  // Retrieve unit price and format it based on selected currency
  const unitPrice = orderDetails.unit_price[currency] || "N/A";

  return (
    <div className="S3main-content">
      {loading && (
        <div className="modal-overlay">
          <div className="preloader-modal">
            <div className="spinner"></div>
            <p className="loading-message">{loadingMessage}</p>
          </div>
        </div>
      )}
      <div className="S3frame-12">
        <div className="PFServicesHeading">
          <div className="ItemDetails-heading">
            <span>Review and Place Order</span>
          </div>
          <p className="S3check-fields">
            It is wise to check every field before placing the order.
          </p>
        </div>
        <div className="S3Fields">
          <div className="S3Frame">
            <div className="S3Frame1">
              <div className="S3Framecolumn">
                <span className="S3Label">Product Name:</span>
                <span className="S3Value"> {orderDetails.name}</span>
              </div>
              <div className="S3Framecolumn">
                <span className="S3Label">Billing Commitment:</span>
                <span className="S3Value"> {orderDetails.commitment}</span>
              </div>
            </div>
            <div className="S3Frame1">
              <div className="S3Framecolumn">
                <span className="S3Label">Price:</span>
                <span className="S3Value">
                  {" "}
                  {currencySymbols[currency]}
                  {orderDetails.unit_price}
                </span>
              </div>
              <div className="S3Framecolumn">
                <span className="S3Label">Quantity:</span>
                <span className="S3Value"> X {orderDetails.quantity}</span>
              </div>
            </div>
            <div className="S3Frame1">
              <div className="S3Framecolumn">
                <span className="S3Label">Billing Plan:</span>
                <span className="S3Value"> {orderDetails.billing}</span>
              </div>
              <div className="S3Framecolumn">
                <span className="S3Label">Subtotal:</span>
                <span className="S3Value">
                  {" "}
                  {currencySymbols[currency]}
                  {orderDetails.total_price} (tax not included)
                </span>
              </div>
            </div>
          </div>
          <div className="S3Frame">
            <div className="S3Frame1 subrow">
                <span className="S3Label">
                  {orderDetails.commitment} commitment, billed{" "}
                  {orderDetails.billing} (before tax):
                </span>
              <div className="S3FrameAmount">
                <span>
                  {currencySymbols[currency]}
                  {orderDetails.total_price}
                </span>
              </div>
            </div>
            {currency === "INR" && ( // Only show if currency is not USD
              <div className="S3Frame1 subrow">
                  <span className="S3Label">Estimate taxes:</span>
                  <div className="S3FrameAmount">
                    <span>
                      {currencySymbols[currency]}
                      {orderDetails.Estimate_tax}
                    </span>
                  </div>
              </div>
            )}
            <div className="S3Frame1 subrow">
              <span className="S3Label">Total ({orderDetails.billing}):</span>
              <div className="S3FrameAmount">
                <span>
                  {currencySymbols[currency]}
                  {orderDetails.grand_total}
                </span>
              </div>
            </div>
          </div>

          <div className="S3Footer">
            {showRetryButton ? (
              <>
                <div className="S3FooterContent">
                  <span>
                    Your payment has been completed. Please click 'Submit' to
                    complete your order.
                  </span>
                  <div onClick={handleRetryClick}>
                    <button className="S3PlaceOrderButton">Submit</button>
                  </div>
                </div>
              </>
            ) : (
              <div className="S3FooterContent">
                <span>
                  {" "}
                  By placing order you are accepting to our terms and conditions
                </span>
                <div onClick={handlePlaceOrderClick}>
                  <button
                    className={`S3PlaceOrderButton ${
                      !isChecked ? "disabled" : ""
                    }`}
                  >
                    Place Order
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="notification-container">
      {notificationMessage && (
        <Notification message={notificationMessage} onClose={handleNotificationClose} />
      )}
      </div>
      {showPopup && (
        <div className="S3popup-overlay">
          <S3PaymentModal close={handleClosePopup} />
        </div>
      )}
      {showModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          {service && <Card service={service} type="preview" />}
        </div>
      )}
    </div>
  );
};

export default ReviewPlaceOrder;
