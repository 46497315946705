import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './Pages/LoginFlow/LoginPage';
import ForgotPwd from './Pages/LoginFlow/ForgotPwd';
import REsetPwd from './Pages/LoginFlow/ResetPwd';
import MSSignup from './Pages/LoginFlow/MSSignup';
import ExistingMSPage from './Pages/LoginFlow/ExistingMSPage';
import LandingPage from './Pages/Dashboard/LandingPage';
import { useParams } from "react-router-dom"
import SignupPage from './Pages/Signup';
import AddSubscriptionPage from './Pages/AddLicences/AddSubscription';
import RenewSubscriptionPage from './Pages/RenewSubscription/RenewSubscription';
import MySubscriptions from './Pages/MyAccountFlow/MySubscriptions';
import PurchaseFlow from './Pages/PurchaceFlow/PurchaceFlow';
import ExistingCustFlow from './Pages/ExistingCustFlow/existingCustFlow';
import SubAddonFlow from './Pages/addonSubscriptions/subAddonFlow';
import Subscription from './Pages/PurchaceFlow/Subscription';
import Subscriptions from './Pages/MyAccountFlow/MySubscriptions';
import RenewSubscription from './Pages/RenewSubscription/RenewSubscription';
import AddSubscription from './Pages/AddLicences/AddSubscription';
import MyOrders from './Pages/MyOrders/MyOrders';
import Cart from './Pages/Cart/Cart';
import SearchResults from './Pages/PurchaceFlow/SearchResults';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import SyncCP from './Pages/SyncCP/SyncCP';

function ResetPwdWithRouter(props) {
    const params = useParams();
    return <REsetPwd {...props} params={params} />;
  }

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/forgot-password" element={<ForgotPwd />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/reset/password/:id" element={<ResetPwdWithRouter/>}/>
                <Route path="/MSSignup" element={<ProtectedRoute element={MSSignup} />}/>
                <Route path='/' element={<LandingPage />}/>
                <Route path='/Home' element={<ProtectedRoute element={LandingPage} />}/>
                <Route path='/search' element={<SearchResults />} />
                <Route path='/signup' element={<SignupPage /> } />
                <Route path='/subscriptions' element={<ProtectedRoute element={Subscriptions} />} />
                <Route path='/order/:id' element={<ProtectedRoute element={PurchaseFlow} />} />
                <Route path='/contract/:id' element={<ProtectedRoute element={ExistingCustFlow} />} />
                <Route path='/contract' element={<ProtectedRoute element={ExistingCustFlow} />} />
                <Route path='/subscription/addon' element={<ProtectedRoute element={SubAddonFlow} />} />
                <Route path='/subscription/addon/:id' element={<ProtectedRoute element={SubAddonFlow} />} />
                <Route path='/order' element={<Subscription />} />
                <Route path='/buy/more/:id/' element={<ProtectedRoute element={AddSubscription} />} />
                <Route path='/myorders' element={<ProtectedRoute element={MyOrders} />} />
                <Route path='/cart' element={<ProtectedRoute element={Cart} />} />
                <Route path='/:id/renew' element={<ProtectedRoute element={RenewSubscription} />} />
                <Route path='/sync/cp' element={<ProtectedRoute element={SyncCP} />} />
            </Routes>
        </Router>
    );
}

export default App;
